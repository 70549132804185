import React from 'react';
import "98.css";
import Window from './components/Window';


export const StatementOfInterdisciplinarity = () => (
    <div>
        <Window title="Statement of Interdisciplinarity"
            content={
                <div>
                    <h5>My name is El Tucker and I am a fourth-year student at Virginia Commonwealth University. I came to VCU curious about computer science and excited to learn more about what I loved to use every day. I started taking a few art classes and realized that I was able to combine computer science with art and mathematics to make video games with an interdisciplinary approach instead of solely engineering. Now that I study these fields together, I can gather a better understanding of game development from many different perspectives. I am particularly interested in making tools for artists to make beautiful and functional games in a timely manner using tools that make sense. If I want to facilitate the creation of art with mathematics I have to understand how to use these tools effectively and efficiently. For example, I would enjoy building a game engine that is accessible to people from all disciplines, including sound design, computer science, and visual design. This is where computer science comes in as it allows me to take my knowledge of mathematics and art and combine it into something tangible that feels seamless and user-friendly. Computer science is also crucial in helping me understand the limitations of what I create. Many of these principles are brought up in computer science theory and not just in programming.</h5>
                    <h5>Interdisciplinarity can be defined as the combination of different fields of study to create something that those fields alone would not create. This allows me to think out of the box because I am able to see game development from different perspectives and catch some assumptions that may not be caught through only one discipline. Game development has a lot of its roots in interdisciplinarity and a plethora of discoveries have been made about computer science, art, and mathematics through this lens. My interdisciplinary values include creation of art that is interactive and leaves a lasting impression on everyone that plays them. I value the experience of brightening someone’s day by offering them an immersive interaction that is rewarding and fun. It is really that simple. I  am honored to aid in the creation and facilitation of impactful art. That is the core catalyst of my interest in interdisciplinary studies. I believe that the creation of games will bring more people together to interact and continue the legacy of gaming that has been around for thousands of years.</h5>
                </div>
            } />
    </div> 
) 